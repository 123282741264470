<template>
  <div class="pageContainer flexCloumn">
    <el-row class="searchBar flexCloumn" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1 w_100" style="flex-wrap:wrap;">

        <el-select v-model="searchForm.status" placeholder="请选择审核状态" size="small"
          style="max-width: 150px; margin-right: 10px; margin-left: 10px">
          <el-option label="待审核" value="0"></el-option>
          <el-option label="未通过" value="-1"></el-option>
          <el-option label="通过" value="1"></el-option>
        </el-select>
        <el-button class="ml10" size="small" icon="el-icon-search" @click="onSearch" type="primary">查询</el-button>
        <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>



    <el-table :data="dataList" height="100%" size="small" stripe border style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
      <el-table-column prop="user_name" label="捐赠人姓名" min-width="200"></el-table-column>
      <el-table-column prop="phone" label="联系方式" width="185"></el-table-column>
      <el-table-column prop="donate_name" label="捐赠类型" width="185"></el-table-column>
      <el-table-column prop="content" label="备注" show-overflow-tooltip min-width="220"></el-table-column>
      <el-table-column fixed="right" prop="status" label="审核状态" width="120">
        <template slot-scope="scope">
          <span style="color: #67c23a;" v-if="scope.row.status == 1">通过</span>
          <span style="color: #ff5500;" v-if="scope.row.status == -1">未通过</span>
          <span style="color: #409eff;" v-if="scope.row.status == 0">待审核</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-button type="text" size="" @click="review(scope.row)" style="margin: 0 !important">查看</el-button>
            <el-button v-if="scope.row.status == 0" class="ml10" type="text" size="" @click="review(scope.row)"
              style="">审核</el-button>
            <el-button v-if="scope.row.status == -1" class="ml10" type="text" size=""
              @click="review(scope.row,'isrecheck')" style="">重新审核</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>
    <el-dialog title="捐赠详情" :visible.sync="dialogVisible" custom-class="cus_dialog" width="60%">
      <el-form ref="form" :model="ruleForm" label-width="120px">
        <el-form-item label="捐赠人姓名："> {{ formData.user_name || '-' }} </el-form-item>
        <el-form-item label="联系方式："> {{ formData.phone || '-' }} </el-form-item>
        <el-form-item label="捐赠类型："> {{ formData.donate_name || '-' }} </el-form-item>
        <el-form-item label="备注："> {{ formData.content || '-' }} </el-form-item>
        <el-form-item v-if="formData.appendix && formData.appendix.length > 0" label="附件图片：">
          <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
            v-for="(img, idx) in formData.appendix" :key="idx">
            <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src"
              :preview-src-list="[img.fileUrl || img.src]" fit="contain"></el-image>
          </div>
        </el-form-item>
        <el-form-item v-if="!formData.appendix || formData.appendix.length == 0" label="附件图片：">-
        </el-form-item>


        <el-form-item v-if="formData.status != 0" label="审核状态：">
          <span style="color: #67c23a;" v-if="formData.status == 1">通过</span>
          <span style="color: #ff5500;" v-if="formData.status == -1">未通过</span>
        </el-form-item>
        <el-form-item v-if="formData.status != 0" label="审核意见：">
          {{ formData.remark || '-' }}
        </el-form-item>
        <el-form-item v-if="formData.status != 0" label="审核人：">
          {{ formData.sp_username || '-' }}
        </el-form-item>
      </el-form>
      <div v-if="formData.status == 0||isrecheck==1" slot="footer" class="flexEnd" style="width: 100%;">
        <span style="white-space: nowrap;">审核意见：</span>
        <el-input v-model="formData.remark" size="small" type="textarea" :autosize="{ minRows: 1 }" maxlength="200"
          placeholder="审核驳回时请填写驳回原因哦~" style="margin-right: 20px;"></el-input>
        <el-button type="danger" @click="onSubmit(-1)" size="small">审核驳回</el-button>
        <el-button type="success" @click="onSubmit(1)" size="small">审核通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {

      activeName: "td",
      dataList: [],
      showSP: false,
      spword: "",
      showPrint: false,
      visible: false,
      detailForm: {},
      dialogVisible: false,
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      searchForm: {},
      ruleForm: {},
      formData: {
        user_name: '',
        phone: '',
        time: '',
        num: '',
        contact_name: '',
        content: '',
        appendix: [],
      },
      isrecheck:0,
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    onView(row) {
      this.visible = true
      this.detailForm = row
    },
    getYears() {
      return new Promise((resolve, reject) => {
        this.$http.post("/api/njwx_stu_years").then(res => {
          this.YearList = res.data
          this.searchForm.year = res.data[0].gyear
          resolve()
        }).catch(err => {
          resolve()

        })
      })
    },
    getlist() {
      this.$http.post("/api/by_donate_list", {
        page: this.page.current_page,
        pagesize: this.page.per_page,
        stu_name: this.searchForm.stu_name,
        activity_name: this.searchForm.activity_name,
        status: this.searchForm.status,
        date_str: this.searchForm.date_str,
      })
        .then((res) => {
          this.dataList = res.data.data;
          this.page = res.data.page
        });
    },
    handlePageChange(page) {
      this.page.current_page = page
      this.getlist()
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getlist()
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.searchForm = {};
      this.getlist();
    },
    review(e,type) {
      console.log(182, e)
      if (e.appendix && this.isJSON(e.appendix)) {
        e.appendix = JSON.parse(e.appendix)
      }
      this.formData = e
      this.dialogVisible = true;
      if (type =='isrecheck') {
        this.isrecheck = 1;
      } else {
        this.isrecheck = 0
      }
    },
    onSubmit(status) {
      this.$http
        .post("/api/check_donate", {
          id: this.formData.id,
          status,
          remark: this.formData.remark
        })
        .then((res) => {
          this.dialogVisible = false;
          this.getlist();
        });
    },
  },
};
</script>
